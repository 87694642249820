// ----------------------------------------------------------------------
// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.
// ----------------------------------------------------------------------
const fr = {
    demo: {
        title: `France`,
        introduction: `Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.`,
    },
    docs: {
        hi: `Bonjour`,
        description: `Besoin d'aide? \n Consultez notre documentation.`,
        documentation: `documentation`,
    },
    dashboard: 'Tableau de bord',
    app: `application`,
    user: `utilisateur`,
    list: `liste`,
    edit: `éditer`,
    shop: `boutique`,
    blog: `blog`,
    post: `poste`,
    mail: `e-mail`,
    chat: `discuter`,
    cards: `cartes`,
    posts: `des postes`,
    create: `créer`,
    kanban: `kanban`,
    general: `général`,
    banking: `bancaire`,
    booking: `réservation`,
    profile: `profil`,
    account: `compte`,
    product: `produit`,
    invoice: `facture`,
    details: `détail`,
    checkout: `vérifier`,
    calendar: `calendrier`,
    analytics: `analytique`,
    ecommerce: `e-commerce`,
    management: `management`,
    menu_level: `niveau menu`,
    menu_level_2a: `niveau menu 2a`,
    menu_level_2b: `niveau menu 2b`,
    menu_level_3a: `niveau menu 3a`,
    menu_level_3b: `niveau menu 3b`,
    menu_level_4a: `niveau menu 4a`,
    menu_level_4b: `niveau menu 4b`,
    item_disabled: `élément désactivé`,
    item_label: `étiquette de l'article`,
    item_caption: `légende de l'article`,
    item_external_link: `lien externe de l'article`,
    description: `la description`,
    other_cases: `autres cas`,
    item_by_roles: `élément par rôles`,
    dashborad: `Tableau de bord`,
    only_admin_can_see_this_item: `seul l'administrateur peut voir cet élément`,
    unarchive: `Désarchiver`,
    archive: `Archiver`,
    archive_question: 'Etes-vous sûr de vouloir archiver ?',
    unarchive_question: 'Etes-vous sûr de vouloir désarchiver ?',
    patient_info: {
        list: 'Liste des patients',
        archivedList: 'Liste des patients archivée',
        title: `Information du patient`,
        avatar: `Image`,
        name: `Nom du patient*`,
        date: `Date de naissance`,
        gender: `Genre`,
        phone: `Numéro de téléphone`,
        addition: `Existe-t-il d'autres informations médicales pertinentes ?`,
        form_place_holder: `Ajouter  les informations`,
        enregistrer: `Enregistrer`,
        create: 'Sélectionner ou créer un patient et continuez',
        save: 'Sauvegarder les modifications',
    },
    patient_list: {
        title: 'Liste',
    },
    lab_list: {
        title: 'Liste Laboratoires Dentaire',
        new: 'Nouvaux',
    },
    lab_form: {
        header: `Ajouter Laboratoire`,
        title: 'Information Laboratoire',
        description: 'Veuillez ajouter un nouveau Laboratoire en remplissant les champs requis',
        name: 'Nom du Laboratoire*',
        phone: 'Numéro de téléphone*',
        address: 'Adresse*',
        email: 'Entrer votre Email*',
        submit: 'Soumettre Laboratoire',
    },
    mat_form: {
        header: `Ajouter Matriel`,
        title: 'Information Matriel',
        description: 'Veuillez ajouter un nouveau Matriel en remplissant les champs requis',
        image: 'Image du Matriel',
        name: 'Nom du Matriel*',
        phone: 'Numéro de téléphone',
        address: 'Adresse',
        email: 'Entrer votre Email',
        submit: 'Soumettre Matriel',
        view_tite: 'Consulter Matriel',
        type: 'Type*',
        price: 'Prix',
    },
    article_form: {
        header: `Soumettre Article`,
        title: 'Information Article',
        description: 'Veuillez ajouter un nouveau Article en remplissant les champs requis',
        name: 'Nom du Article*',
        phone: 'Numéro de téléphone',
        address: 'Adresse',
        email: 'Entrer votre Email',
        submit: 'Soumettre Article',
        min: 'Nombre minimum de jours*',
        max: 'Nombre maximum de jours*',
        view_tite: 'Consulter Article',
        type: 'Type*',
        price: 'Prix',
    },
    type_form: {
        header: `Ajouter Type`,
        title: 'Information Type',
        description: 'Veuillez ajouter un nouveau Type en remplissant les champs requis',
        name: 'Nom du Type',
        label: 'Label*',
        code: 'Code*',
        address: 'Adresse',
        email: 'Entrer votre Email',
        submit: 'Ajouter Type',
        view_tite: 'Consulter Type',
        type: 'Type',
        price: 'Prix*',
    },
    cabinet_form: {
        new: 'Nouveau Cabinet / Sous Traitant',
        list: 'Liste des Cabinets  / Sous Traitants dentaires',
        list_archived: 'Liste des cabinets dentaires archivée',
        header: `Ajouter Cabinet`,
        title: 'Information Cabinet',
        description: 'Veuillez ajouter un nouveau Cabinet en remplissant les champs requis',
        name: 'Nom du Cabinet*',
        phone: 'Numéro de téléphone*',
        email: 'Entrer votre Email*',
        matricule: 'Entrer votre Matricule*',
        address: 'Adresse*',
        laboratory: 'Laboratoire*',
        submit: 'Soumettre cabinet',
        enregister: 'Enregistrer',
        created: 'Cabinet créé avec succès !',
        error_creation: `Erreur lors de la création du cabinet`,
    },
    prothesist_form: {
        new: 'Nouveau Prothesist',
        list: 'Liste des Prothesist',
        list_archived: 'Liste Prothesist Archivé',
        header: `Ajouter Prothesist`,
        title: 'Information Prothesist',
        description: 'Veuillez ajouter un nouveau Prothesist en remplissant les champs requis',
        name: 'prénom du Prothesist',
        phone: 'Numéro de téléphone',
        email: 'Entrer votre Email',
        last_name: 'nom du prothésiste',
        submit: 'Ajouter Prothesist',
        enregister: 'Enregistrer',
        created: 'Prothesist créé avec succès !',
        error_creation: `Erreur lors de la création du Prothesist`,
    },
    dentist_form: {
        header: `Information dentiste`,
        name: 'Entrer  le nom du dentiste',
        last_name: 'Entrer  le prénom du dentiste',
        description: 'Il faut ajouter au minimum un dentiste*',
        phone: 'Numéro de téléphone',
        submit: 'Ajouter dentiste',
        cancel: 'Annuler',
        created: 'Dentiste créé avec succès !',
        error_creation: `Erreur lors de la création du dentiste`,
        speciality: `Specialité`,
    },
    login: {
        title: 'Plateforme de Gestion pour Prothésistes Dentaires.',
        subtitle: 'Connectez-vous',
        email: 'Email',
        email_placeholder: 'Entrez votre email professionel',
        password: 'Mot de passe',
        password_placeholder: 'Tapez votre mot de passe',
        forgetPassword: 'mot de passe oublié ?',
        submit_btn: 'Se connecter',
        copyright: 'Tous droits réservés',
    },
    forget_password: {
        title: 'Mot de passe oublié?',
        description: 'Entrez votre adresse e-mail pour réinitialiser votre mot de passe ou activer votre compte. Nous vous enverrons un lien de vérification vers votre e-mail.',
        email: 'Email',
        submit_btn: 'confirmer',
        backToLoginBtn: 'Retourner à se connecter',
    },
    resetPassword: {
        title: 'Réinitialisation du mot de passe',
        description: 'Pour réinitialiser votre mot de passe ou activer votre compte, veuillez créer un nouveau mot de passe et le confirmer ci-dessous. Ensuite, vous serez dirigé vers la page de connexion.',
        password: 'Mot de passe',
        confirm_password: 'confirmer le nouveau mot de passe',
        submit_btn: 'Réinitialisation du mot de passe',
        backToLoginBtn: 'Retourner à se connecter',
    },
    form: {
        name_required: 'Le nom est requis',
        address_required: `L'adresse est requise`,
        phone_required: `Le numéro de téléphone est requis`,
        lastName_required: `Le prénom est requis`,
        phone_numeric: 'Le numéro de téléphone doit être numérique',
        phone_length: `Le numéro de téléphone doit comporter 8 caractères`,
        lab_required: `Laboratoire est requise`,
        add_phone: 'Ajoutée Numéro téléphone',
        delete: 'Supprimer',
        delete_confirm: 'Êtes-vous sûr de vouloir supprimer',
        speciality_required: `speciality est requis`,
    },
    profile_picture: 'Photo de profil',
    male: 'Homme',
    female: 'Femme',
    permission_list: 'Liste des autorisations',
    updatePassword: {
        title: 'Modification du mot de passe',
        description: 'Pour réinitialiser votre mot de passe ou activer votre compte, veuillez créer un nouveau mot de passe et le confirmer ci-dessous. Ensuite, vous serez dirigé vers le tableau de bord.',
        old_password: 'Ancien Mot de passe',
        password: 'Nouveau Mot de passe',
        confirm_password: 'confirmer le nouveau mot de passe',
        submit_btn: 'Modifier le mot de passe',
    },
    option_form: {
        add: {
            header: `Ajouter une option`,
            title: "Information d'option",
            description: 'Veuillez ajouter une nouveau Option en remplissant les champs requis',
            type: 'Ajouter le type',
            options: 'Ajouter les options',
        },
        update: {
            header: "Modifier d'option",
            title: "Information d'option",
            description: 'Veuillez modifiez une nouveau Option en remplissant les champs requis',
            type: 'Modifier le type',
            options: 'Modifier les options',
        },
        view_tite: 'Consulter une Option',
        type: 'Type*',
        name: 'Nom*',
        color: 'Couleur*',
        min: 'Min*',
        max: 'Max*',
        step: 'Step*',
        image: 'Image',
        addNewOption: 'Ajouter une Nouvelle Option',
        submit: 'Enregistrer',
        laboratory: 'Laboratoire',
    },
    Prosthetist_form: {
        add: {
            header: `Ajouter Prothésiste`,
            title: 'Information Prothésiste',
            description: 'Veuillez ajouter un nouveau Prothésiste en remplissant les champs requis',
            submit: 'Ajouter Prothésiste',
        },
        update: {
            header: 'Modifier le Prothésiste',
            title: 'Information Prothésiste',
            description: 'Veuillez modifier un Prothésiste en remplissant les champs requis',
            submit: 'Modifier le Prothésiste',
        },
        name: 'Prénom du Prothésiste*',
        lastName: 'Nom  du Prothésiste*',
        phone: 'Numéro de téléphone*',
        laboratory: 'laboratoire*',
        email: 'Email*',
        password: 'Mot de Passe*',
        roles: 'Rôles',
        avatar: 'Image',
    },
    option_list: {
        optionSetting: {
            update: 'Modifier',
            delete: 'Supprimer',
        },
    },
    deleteModal: {
        title: 'Supprimer',
        description: 'Etes-vous sûr de vouloir supprimer ?',
        cancelBtnText: 'annuler',
        okBtnText: 'Supprimer',
    },
    marked_seen: 'Notifications marquées avec succès comme lues',
    marked_one_seen: 'Notification marquées avec succès comme lue',
    set_read: 'Marquer comme lue',
    empty: 'Cette section est vide',
    patient_card_info: 'Informations médicales pertinentes :',
    update_Patient: {
        header: 'Modifier le Patient',
        title_info_section: 'Modifier les Informations du Patient',
        title_medical_section: "Existe-t-il d'autres informations médicales pertinentes ?",
        description: 'Veuillez modifiez le Patient en remplissant les champs requis',
        submit: 'Modifier le Patient',
    },
    dentist_user_form: {
        new: 'Nouveau Praticien',
        list: 'Liste des Praticiens',
        list_archived: 'Liste Praticiens Archivé',
        header: `Ajouter Praticien`,
        title: 'Information Praticien',
        descriptionCreate: 'Veuillez ajouter un nouveau Praticien en remplissant les champs requis',
        descriptionUpdate: 'Veuillez modifier un Praticien en modifiant les champs requis',
        name: 'Prénom du Praticien*',
        avatar: 'Image du Praticien',
        lastName: 'Nom du Praticien*',
        password: 'Mot de passe*',
        phone: 'Numéro de téléphone*',
        email: 'Entrer votre Email*',
        roles: 'Roles',
        cabinet: 'Cabinet*',
        submitCreate: 'Ajouter Praticien',
        submitUpdate: 'Modifier Praticien',
        enregister: 'Enregistrer',
        created: 'Praticien créé avec succès !',
        error_creation: `Erreur lors de la création du Praticien`,
        'patient with phone number :{{phone}} already exists!': 'patient avec ce numéro de téléphone existe déjà !',
    },
    /* error article*/
    'All articles returned successfuly': `Tous les articles ont été retournés avec succès`,
    'Article has been created successfully!': `L'article a été créé avec succès !`,
    'Article Deleted': `Article supprimé`,
    success: `succès`,
    'Article updated': `Article mis à jour.`,
    CREATE: 'Créer',
    READ: 'Lire',
    UPDATE: 'Mise à jour',
    DELETE: 'Supprimer',
    ARCHIVE: 'Archiver',
    UNARCHIVE: 'Désarchiver',
    ALL: 'Tout',
    /* error auth*/
    'User not registered': 'Utilisateur non enregistré',
    'Credential not set': 'Identifiant non défini',
    'Invalid Password': 'Mot de passe invalide',
    'Invalid access token': "Jeton d'accès invalide",
    'No user found with this email': 'Aucun utilisateur trouvé avec cet e-mail',
    'No reset password code provided': 'Aucun code de réinitialisation du mot de passe fourni',
    'token is wrong or has expired': 'Le jeton est incorrect ou a expiré',
    "You don't belong to this laboratory": "Vous n'appartenez pas à ce laboratoire",
    'No file uploaded': 'Aucun fichier téléchargé',
    'No user found with that id': 'Aucun utilisateur trouvé avec cet identifiant',
    'No prothese found with that id': 'Aucune prothèse trouvée avec cet identifiant',
    'A laboratory with the same email or phone already exists': 'Un laboratoire avec le même e-mail ou téléphone existe déjà',
    'Error authentication': "Erreur d'authentification",
    'error download file or directory': 'erreur de téléchargement de fichier ou de répertoire',
    'verify your credentials': 'Vérifiez vos identifiants',
    'Password reset successfully': 'Réinitialisation du mot de passe réussie',
    'Login Success': 'Connexion réussie',
    'Logout success': 'Déconnexion réussie',
    'Token Issued': 'Jeton émis',
    'Profile updated': 'Profil mis à jour',
    /* error cabinet*/
    'All Cabinets returned successfuly': 'Tous les cabinets ont été retournés avec succès',
    'Cabinet has been created successfully!': 'Le cabinet a été créé avec succès !',
    'Cabinet Deleted': 'Cabinet supprimé',
    'Cabinet updated': 'Cabinet mis à jour',
    'Cabinet archived successfully': 'Cabinet archivé avec succès',
    'Cabinet unarchived successfully': 'Cabinet désarchivé avec succès',
    "you can't put same phones": 'vous ne pouvez pas mettre les mêmes téléphones',
    'cabinet with this phone already exist': 'un cabinet avec ce numéro de téléphone existe déjà',
    'no laboratory found with that id': 'aucun laboratoire trouvé avec cet identifiant',
    "You don't belong to this office": "Vous n'appartenez pas à ce cabinet",
    /* error color*/
    'All colors returned successfuly': 'Toutes les couleurs ont été retournées avec succès',
    'Color has been created successfully!': 'La couleur a été créée avec succès !',
    'Color deleted successfully': 'Couleur supprimée avec succès',
    'color updated': 'couleur mise à jour',
    'No color found with that id': 'Aucune couleur trouvée avec cet identifiant',
    /* error command*/
    'All commands returned successfuly': 'Toutes les commandes ont été retournées avec succès',
    'Command has been created successfully!': 'La commande a été créée avec succès !',
    'Invalid date': 'Date invalide',
    'Cabinet not found': 'Cabinet non trouvé',
    'No patient card found with that id': 'Aucune carte patient trouvée avec cet identifiant',
    'This command does not belong to you': 'Cette commande ne vous appartient pas',
    'No command found with that id': 'Aucune commande trouvée avec cet identifiant',
    'No prothesist found with that id': 'Aucun prothésiste trouvé avec cet identifiant',
    'Command archived successfully': 'Commande archivée avec succès',
    'Command unarchived successfully': 'Commande désarchivée avec succès',
    'This command is already accepted by another prothesist': 'Cette commande est déjà acceptée par un autre prothésiste',
    "You're not a prothesist to accept this command": "Vous n'êtes pas un prothésiste pour accepter cette commande",
    'Command updated': 'Commande mise à jour',
    'All users returned successfuly': 'Tous les utilisateurs ont été retournés avec succès',
    'User Deleted': 'Utilisateur supprimé',
    'No cabinet found with that id': 'Aucun cabinet trouvé avec cet identifiant',
    'Dentist already exists': 'Le dentiste existe déjà',
    'Dentist created successfully': 'Dentiste créé avec succès',
    'Dentists returned successfully': 'Dentistes retournés avec succès',
    'Role not found': 'Rôle non trouvé',
    'Dentist deleted successfully': 'Dentiste supprimé avec succès',
    'Dentist fetched successfully': 'Dentiste récupéré avec succès',
    'Dentist updated successfully': 'Dentiste mis à jour avec succès',
    'No dentist found with that id': 'Aucun dentiste trouvé avec cet identifiant',
    'No office found with that id': 'Aucun cabinet trouvé avec cet identifiant',
    'user with the email already exists': "utilisateur avec l'e-mail existe déjà",
    'All cartePatients returned successfuly': 'Toutes les cartes patients ont été retournées avec succès',
    'Patient card and a command have been created successfully!': 'La carte patient et une commande ont été créées avec succès !',
    'cartePatient Deleted': 'Carte patient supprimée',
    'cartePatient updated': 'Carte patient mise à jour',
    /* error history*/
    'All Histories returned successfuly': 'Tous les historiques ont été retournés avec succès',
    'History has been created successfully!': "L'historique a été créé avec succès !",
    'History Deleted': 'Historique supprimé',
    'History updated': 'Historique mis à jour',
    /* error laboratory*/
    'All laboratories returned successfuly': 'Tous les laboratoires ont été retournés avec succès',
    'Laboratory has been created successfully!': 'Le laboratoire a été créé avec succès !',
    'Laboratory Deleted': 'Laboratoire supprimé',
    'CabiLaboratorynet updated': 'Laboratoire mis à jour',
    /* error material*/
    'All materials returned successfuly': 'Tous les matériaux ont été retournés avec succès',
    'material has been created successfully!': 'Le matériau a été créé avec succès !',
    'material Deleted': 'Matériau supprimé',
    'material updated': 'Matériau mis à jour',
    'No type found with that id': 'Aucun type trouvé avec cet identifiant',
    /* All message for options */
    'All options returned successfully': 'Toutes les options ont été renvoyées avec succès',
    'No valid images were uploaded.': "Aucune image valide n'a été téléchargée.",
    'The number of uploaded images must match the number of options.': "Le nombre d'images téléchargées doit correspondre au nombre d'options.",
    'Option has been created successfully!': "L'option a été créée avec succès !",
    'Option Added': 'Option ajoutée',
    'No option found with that id': 'Aucune option trouvée avec cet identifiant',
    'Option removed': 'Option retirée',
    'Option Deleted': 'Option supprimée',
    'Option updated': 'Option mise à jour',
    'Options has been returned successfully!': 'Les options ont été renvoyées avec succès !',
    /* message for notification */
    sucess: 'succès',
    'Notifications successfully marked as read': 'Notifications marquées comme lues avec succès',
    'Notification not found': 'Notification non trouvée',
    'Notification already seen !': 'Notification déjà vue !',
    'Unread notifications number': 'Nombre de notifications non lues',
    /* Patient */
    'All patients returned successfuly': 'Tous les patients ont été renvoyés avec succès',
    'Patient has been created successfully!': 'Le patient a été créé avec succès !',
    'patient deleted successfully': 'Patient supprimé avec succès',
    'No patient found with that id': 'Aucun patient trouvé avec cet identifiant',
    'Patient updated': 'Patient mis à jour',
    'Patient archived successfully': 'Patient archivé avec succès',
    'Patient unarchived successfully': 'Patient désarchivé avec succès',
    'Invalid age': 'Âge invalide',
    'patient with phone number already exists!': 'Le patient avec ce numéro de téléphone existe déjà!',
    /* Permission */
    'Permissions found successfully!': 'Permissions trouvées avec succès!',
    'Permission has been created successfully!': 'La permission a été créée avec succès!',
    'Permission Deleted': 'Permission supprimée',
    'permission updated': 'permission mise à jour',
    /* Prothesist */
    'Prothesist created successfully': 'Prothésiste créé avec succès',
    'Prothesists returned successfully': 'Prothésistes retournés avec succès',
    'Prothesist deleted successfully': 'Prothésiste supprimé avec succès',
    'Prothesist fetched successfully': 'Prothésiste récupéré avec succès',
    'Prothesist updated successfully': 'Prothésiste mis à jour avec succès',
    'No laboratory found with that id': 'Aucun laboratoire trouvé avec cet identifiant',
    'User with the phone : {phone} already exists': "L'utilisateur avec le numéro de téléphone : {phone} existe déjà",
    'User with the email : {email} already exists': "L'utilisateur avec l'adresse e-mail : {email} existe déjà",
    "Role doesn't exist": "Le rôle n'existe pas",
    /* Prothese */
    'All Protheses returned successfuly': 'Toutes les prothèses ont été renvoyées avec succès',
    'Prothese has been created successfully!': 'La prothèse a été créée avec succès !',
    'Prothese Deleted': 'Prothèse supprimée',
    'Prothese updated': 'Prothèse mise à jour',
    'You dont belong to this office': "Vous n'appartenez pas à ce cabinet",
    Success: 'Succès',
    /* Roles */
    'All Role returned successfuly': 'Tous les rôles ont été renvoyés avec succès',
    'Role has been created successfully!': 'Le rôle a été créé avec succès !',
    "you can't delete {role.label}": 'Vous ne pouvez pas supprimer {role.label}',
    "you can't update {role.label}": 'Vous ne pouvez pas mettre à jour {role.label}',
    /* Traitement */
    'All Traitements returned successfuly': 'Tous les traitements ont été renvoyés avec succès',
    'Traitement has been created successfully!': 'Le traitement a été créé avec succès!',
    'Traitement deleted successfully': 'Traitement supprimé avec succès',
    'Traitement updated': 'Traitement mis à jour',
    'This treatment does not belong to you': 'Ce traitement ne vous appartient pas',
    'no article found with that id': 'Aucun article trouvé avec cet identifiant',
    'No material found with that id': 'Aucun matériau trouvé avec cet identifiant',
    'no prothese found with that id': 'Aucune prothèse trouvée avec cet identifiant',
    'No treatment found with that id': 'Aucun traitement trouvé avec cet identifiant',
    'Traitement archived successfully': 'Traitement archivé avec succès',
    'traitement unarchived successfully': 'Traitement désarchivé avec succès',
    /* user */
    'User not registered or deleted': 'Utilisateur non enregistré ou supprimé',
    'User not found': 'Utilisateur introuvable',
    'User updated': 'Utilisateur mis à jour',
    /* type */
    'All types returned successfuly': 'Tous les types ont été renvoyés avec succès',
    'Type has been created successfully!': 'Le type a été créé avec succès !',
    'Type Deleted': 'Type supprimé',
    'type updated': 'type mis à jour',
};
export default fr;
